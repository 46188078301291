<script setup>
    import PersonLookup from 'o365.vue.components.PersonsLookup.vue';
    import * as DataObjects from 'arena.vue.components.AttributesDataObjects.js';
    import translate from 'o365.modules.translate.ts'
    import { ref, computed } from 'vue';
    import { getOrCreateDataObject, getDataObjectById } from 'o365.vue.ts'

    const props = defineProps({
        dataObject: Object,
        showAttributes: { default: true },
        customClass: { type: String, required: false, default:'col-lg-3' },
        lazySave: { default: true }
    })
    const requiredFields = ref("");
    const hiddenFields = ref("");
    const searchForAttributes = ref("");

    const local_dsClassificationCode = getOrCreateDataObject(DataObjects.local_dsClassificationCode),
    local_dsReviewClasses = getOrCreateDataObject(DataObjects.local_dsReviewClasses),
    local_dsTopics = getOrCreateDataObject(DataObjects.local_dsTopics),
    local_dsProjectCodes = getOrCreateDataObject(DataObjects.local_dsProjectCodes),
    local_dsDisciplines = getOrCreateDataObject(DataObjects.local_dsDisciplines),
    local_dsProjectPhases = getOrCreateDataObject(DataObjects.local_dsProjectPhases),
    local_dsMainAreas = getOrCreateDataObject(DataObjects.local_dsMainAreas),
    local_dsPersonsLkp = getOrCreateDataObject(DataObjects.local_dsPersonsLkp),
    local_dsOrgUnitsLkp = getOrCreateDataObject(DataObjects.local_dsOrgUnitsLkp),
    local_dsSubProjects = getOrCreateDataObject(DataObjects.local_dsSubProjects),
    local_dsMetaLookup =  getOrCreateDataObject(DataObjects.local_dsMetaLookup),
    local_dsMetaFields =  getOrCreateDataObject(DataObjects.local_dsMetaFields),
    local_dsCodesLookup =  getOrCreateDataObject(DataObjects.local_dsCodesLookup),
    local_dsCodeTypes =  getOrCreateDataObject(DataObjects.local_dsCodeTypes),
    local_dsCompanies =  getOrCreateDataObject(DataObjects.local_dsCompanies),
    local_dsPlannedChangeLkp =  getOrCreateDataObject(DataObjects.local_dsPlannedChangeLkp),
    local_dsDistReqTypes =  getOrCreateDataObject(DataObjects.local_dsDistReqTypes),
    local_dsDocumentPackages =  getOrCreateDataObject(DataObjects.local_dsDocumentPackages),
    local_dsExtProStatuses =  getOrCreateDataObject(DataObjects.local_dsExtProStatuses),
    local_dsExtProcesses =  getOrCreateDataObject(DataObjects.local_dsExtProcesses),
    local_dsVoidReasons =  getOrCreateDataObject(DataObjects.local_dsVoidReasons),
    local_dsRetentionCodes =  getOrCreateDataObject(DataObjects.local_dsRetentionCodes),
    local_dsFreeTextLkp =  getOrCreateDataObject({
        id: 'dsFreeTextLkp',
        viewName: 'aviw_Arena_FreeTextLookup',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Domain_ID",
            masterField: "Domain_ID",
            operator: "equals"
        }],
        fields:
            [{name: "FreeText", type: "string" }]
    }),
    local_dsErrors = getOrCreateDataObject({
        id: 'dsErrors',
        viewName: 'atbv_Arena_DocumentsErrors',
        maxRecords: -1,
        whereClause: "",
        loadRecents: true,
        distinctRows: true,
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "Document_ID",
            masterField: "ID",
            operator: "equals"
        }],
        fields:
            [{name: "Error", type: "string"},
            {name: "FieldName", type: "string" }]
    }),
    local_dsWBS = getOrCreateDataObject({
        id: 'dsWBS',
        viewName: 'aviw_Complete_WBS',
        maxRecords: -1,
        whereClause: "",
        loadRecents: true,
        distinctRows: true,
        fields:
            [{name: "ID", type: "number"},
            {name: "Name", type: "string" },
            {name: "Title", type: "string" },
            {name: "Parent", type: "string" },
            {name: "NameAndTitle", type: "string" }],
        masterDataObject_ID: props.dataObject.id,
        masterDetailDefinition: [{
            detailField: "OrgUnit_ID",
            masterField: "CostProject_ID",
            operator: "equals"
        }],
    })

    function selectCheckBoxValues(pSel, pColumn, pDO){
        let temp = '';
        if(pSel){
            pSel.map((item) => {
                temp += item.Name + ', ';
            })
        }else{
            getDataObjectById(pDO).data.forEach(row => {
                row.isSelected = false;
            });
        }
        props.dataObject.current[pColumn] = temp.slice(0, -2);
        props.dataObject.save();
    }

    function selectInvolvedContractsbeforeOpen() {
        if(!props.dataObject.current.InvolvedContracts)
            return;

        const testArr = props.dataObject.current.InvolvedContracts.split(', ');
        local_dsOrgUnitsLkp.data.forEach(row => {
            if (testArr.indexOf(row.Name) !== -1) {

                row.isSelected = true;

            }else{
                row.isSelected = false;
            }

        });
    }
    function selectsubProjectsbeforeOpen() {
        if(!props.dataObject.current.SubProjects)
            return;

        const testArr = props.dataObject.current.SubProjects.split(', ');
        local_dsSubProjects.data.forEach(row => {

            if (testArr.indexOf(row.Name) !== -1) {

                row.isSelected = true;

            }else{
                row.isSelected = false;
            }

        });
    }

    function getRequiredFields(){
        requiredFields.value = {}
        local_dsErrors.data.map((row)=>{
            if(!props.dataObject.current[row.FieldName]){
                requiredFields.value[row.FieldName] = true;
                requiredFields.value[row.FieldName+'Error'] = row.Error;
            }
        })
    }
    props.dataObject.on('AfterSave',() => {
        hiddenFields.value = props.dataObject.current.HiddenFields.split(',').map(element => element.trim());
        local_dsErrors.load().then(()=>{
            getRequiredFields();
        });
    })
    props.dataObject.on('DataLoaded', function(){
        hiddenFields.value = props.dataObject.current.HiddenFields.split(',').map(element => element.trim());
    })
    local_dsErrors.on('DataLoaded', () => {
        getRequiredFields();
    })
    
    local_dsOrgUnitsLkp.on('DataLoaded', function(){
        selectInvolvedContractsbeforeOpen();
    })
    local_dsSubProjects.on('DataLoaded', function(){
        selectsubProjectsbeforeOpen();
    })

    const attributes = ref([
        {
            Label: translate('Document Group'),
            Field: 'DocumentGroup',
            InputType: 'input',
            Disabled: true
        },
        {
            Label: translate('Contractor Document No'),
            Field: "ContractorDocumentNo",
            RequiredField: "ContractorDocNo",
            InputType: 'input'
        },
        {
            Label: translate('Client Document Number'),
            Field: "ClientDocumentNo",
            InputType: 'input'
        },
        {
            Label: translate('Responsible'),
            Field: "Responsible",
            RequiredField: "Responsible_ID",
            InputType: 'ODataLookup',
            DataObject: local_dsPersonsLkp,
            Bind: (sel) => {props.dataObject.current.Responsible_ID = sel.ID, props.dataObject.current.Responsible = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'MobileNo', headerName:'MobileNo', width:100},
                {field:'Email', headerName:'Email', width:200},
                {field:'Represents', headerName:'Represents', width:200}
            ]
        },
        {
            Label: translate('Delivered'),
            Field: 'Delivered',
            InputType: 'input',
            Disabled: true
        },
        {
            Label: translate('Classification Code'),
            Field: "ClassificationNameAndTitle",
            RequiredField: "Classification",
            InputType: 'ODataLookup',
            DataObject: local_dsClassificationCode,
            Bind: (sel) => {props.dataObject.current.ClassificationCode_ID = sel.ID, props.dataObject.current.ClassificationNameAndTitle = sel.NameAndTitle},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200}
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%' AND Closed IS NULL"
        },
        {
            Label: translate('Topic'),
            Field: "Topic",
            RequiredField: "Topic",
            InputType: 'ODataLookup',
            DataObject: local_dsTopics,
            Bind: (sel) => {props.dataObject.current.Topic_ID = sel.ID, props.dataObject.current.Topic = sel.NameAndDescription;},
            Columns: [
                {field:'NameAndDescription', headerName:'Name And Description', width:300}
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'"
        },
        {
            Label: translate('Project Code'),
            Field: "ProjectCode",
            RequiredField: "ProjectCode",
            InputType: 'ODataLookup',
            DataObject: local_dsProjectCodes,
            Bind: (sel) => {props.dataObject.current.ProjectCode_ID = sel.ID, props.dataObject.current.ProjectCode = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'OrgUnit', headerName:'OrgUnit', width:200},
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'"
        },
        {
            Label: translate('Discipline'),
            Field: "DisciplineNameAndTitle",
            RequiredField: "Discipline",
            InputType: 'ODataLookup',
            DataObject: local_dsDisciplines,
            Bind: (sel) => {props.dataObject.current.Discipline_ID = sel.ID, props.dataObject.current.DisciplineNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%' AND Closed IS NULL"
        },
        {
            Label: translate('Project Phase'),
            Field: "ProjectPhase",
            RequiredField: "ProjectPhase",
            InputType: 'ODataLookup',
            DataObject: local_dsProjectPhases,
            Bind: (sel) => {props.dataObject.current.ProjectPhase_ID = sel.ID, props.dataObject.current.ProjectPhase = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ]
        },
        {
            Label: translate('Main Area'),
            Field: "MainArea",
            RequiredField: "MainArea",
            InputType: 'ODataLookup',
            DataObject: local_dsMainAreas,
            Bind: (sel) => {props.dataObject.current.MainArea_ID = sel.ID, props.dataObject.current.MainArea = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:200},
                {field:'Title', headerName:'Title', width:200},
            ],
            WhereClause: "(DocumentGroup_ID = " + props.dataObject.current.DocumentGroup_ID + " OR DocumentGroup_ID IS NULL) AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]"
        },
        {
            Label: translate('Sub Projects'),
            Field: "SubProjects",
            RequiredField: "SubProjects",
            InputType: 'ODataLookup',
            DataObject: local_dsSubProjects,
            Bind: (sel) => selectCheckBoxValues(sel,'SubProjects', 'dsSubProjects'),
            Columns: [
                {field:'Name', headerName:'Name', width:400}
            ],
            WhereClause: "'" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'",
            MultiSelect: true,
            BindClear: () => selectCheckBoxValues(null,'SubProjects', 'dsSubProjects')
        },
        {
            Label: translate('Involved Contracts'),
            Field: "InvolvedContracts",
            RequiredField: "InvolvedContracts",
            InputType: 'ODataLookup',
            DataObject: local_dsOrgUnitsLkp,
            Bind: sel => selectCheckBoxValues(sel,'InvolvedContracts', 'local_dsOrgUnitsLkp'),
            Columns: [
                {field:'NameAndTitle', headerName:'NameAndTitle', width:200},
                {field:'UnitType', headerName:'UnitType', width:100},
                {field:'Parent', headerName:'Parent', width:200},
            ],
            WhereClause: "[IdPath] LIKE '" + props.dataObject.current.OrgUnitIdPath + "%'",
            MultiSelect: true,
            BindClear: () => selectCheckBoxValues(null,'InvolvedContracts', 'local_dsOrgUnitsLkp')
        },
        {
            Label: translate('Review Class'),
            Field: "ReviewClass",
            InputType: 'ODataLookup',
            DataObject: local_dsReviewClasses,
            Bind: sel => {props.dataObject.current.ReviewClass_ID = sel.ID, props.dataObject.current.ReviewClass = sel.Name;},
            Columns: [
                {field:'ID', headerName:'ID', width:100},
                {field:'Name', headerName:'Name', width:200},
            ]
        },
        {
            Label: translate('Keywords'),
            Field: "Keywords",
            InputType: 'input'
        },
        {
            Label: translate('Progress / Weighting'),
            Field: "Progress",
            Field2: "ProgressWeighting",
            InputType: 'input2x'
        },
        {
            Label: translate('WBS'),
            Field: "WBSNameAndTitle",
            InputType: 'ODataLookup',
            DataObject: local_dsWBS,
            Bind: sel => {props.dataObject.current.WBS_ID = sel.ID, props.dataObject.current.WBSNameAndTitle = sel.NameAndTitle;},
            Columns: [
                {field:'Parent', headerName:'Parent', width:200},
                {field:'Title', headerName:'Title', width:200},
                {field:'Name', headerName:'Name', width:200}
            ]
        },
        {
            Label: translate('Comments'),
            Field: "Comments",
            InputType: 'OTextArea'
        },
        {
            Label: translate('Next Delivery Date'),
            Field: "NextDeliveryDate",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('PlannedIDC'),
            Field: "PlannedIDC",
            RequiredField: "PlannedIDC",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('PlannedIFR'),
            Field: "PlannedIFR",
            RequiredField: "PlannedIFR",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('PlannedIFU'),
            Field: "PlannedIFU",
            RequiredField: "PlannedIFU",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('PlannedASB'),
            Field: "PlannedASB",
            RequiredField: "PlannedASB",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('PlannedStart'),
            Field: "PlannedStart",
            RequiredField: "PlannedStart",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('PlannedVerified'),
            Field: "PlannedVerified",
            RequiredField: "PlannedVerified",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('PlannedReleased'),
            Field: "PlannedReleased",
            RequiredField: "PlannedReleased",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('ForecastIDC'),
            Field: "ForecastIDC",
            RequiredField: "ForecastIDC",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('ForecastIFR'),
            Field: "ForecastIFR",
            RequiredField: "ForecastIFR",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('ForecastIFU'),
            Field: "ForecastIFU",
            RequiredField: "ForecastIFU",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('ForecastASB'),
            Field: "ForecastASB",
            RequiredField: "ForecastASB",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('ForecastStart'),
            Field: "ForecastStart",
            RequiredField: "ForecastStart",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('ForecastVerified'),
            Field: "ForecastVerified",
            RequiredField: "ForecastVerified",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('ForecastReleased'),
            Field: "ForecastReleased",
            RequiredField: "ForecastReleased",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('Expiry Tracked By'),
            Field: "ExpiryTrackedBy",
            InputType: 'PersonLookup',
            Bind: sel => { props.dataObject.current.ExpiryTrackedBy_ID = sel.ID, props.dataObject.current.ExpiryTrackedBy = sel.Name;}
        },
        {
            Label: translate('Expiry Date'),
            Field: "ExpiryDate",
            InputType: 'o-date-picker'
        },
        {
            Label: translate('Person (Applies To)'),
            Field: "Person",
            InputType: 'PersonLookup',
            Bind: sel => {props.dataObject.current.Person_ID = sel.ID, props.dataObject.current.Person = sel.Name;}
        },
        {
            Label: translate('Retention Code'),
            Field: "RetentionCode",
            InputType: 'ODataLookup',
            DataObject: local_dsRetentionCodes,
            Bind: sel => {props.dataObject.current.RetentionCode = sel.Code;},
            Columns: [
                {field:'Code', headerName:'Code', width:200},
                {field:'Description', headerName:'Description', width:200}
            ]
        },
        {
            Label: translate('Archived'),
            Field: "ArchivedDate",
            InputType: "o-date-picker"
        },
        {
            Label: translate('Archived By'),
            Field: 'ArchivedBy',
            InputType: 'input',
            Disabled: true
        },
        {
            Label: translate('Obsolete'),
            Field: 'Obsolete',
            InputType: "o-date-picker"
        },
        {
            Label: translate('Obsolete Reason'),
            Field: "ObsoleteReason",
            InputType: 'ODataLookup',
            DataObject: local_dsVoidReasons,
            Bind: sel => {props.dataObject.current.ObsoleteReason_ID = sel.VoidReason_ID; props.dataObject.current.ObsoleteReason= sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ]
        },
        {
            Label: translate('External Process'),
            Field: "ExternalProcessName",
            InputType: 'ODataLookup',
            DataObject: local_dsExtProcesses,
            Bind: sel => {props.dataObject.current.ExternalProcessName = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ]
        },
        {
            Label: translate('External Process Status'),
            Field: "ExternalProcessStatus",
            InputType: 'ODataLookup',
            DataObject: local_dsExtProStatuses,
            Bind: sel => {props.dataObject.current.ExternalProcessStatus = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ]
        },
        {
            Label: translate('External Process Due Date'),
            Field: 'ExternalProcessDueDate',
            InputType: "o-date-picker"
        },
        {
            Label: translate('External Process Sent Date'),
            Field: 'ExternalProcessSentDate',
            InputType: "o-date-picker"
        },
        {
            Label: translate('External Process Received Date'),
            Field: 'ExternalProcessReceivedDate',
            InputType: "o-date-picker"
        },
        {
            Label: translate('Document Package'),
            Field: "PackageNameAndTitle",
            RequiredField: "DocumentPackage",
            InputType: 'ODataLookup',
            DataObject: local_dsDocumentPackages,
            Bind: sel => {props.dataObject.current.PackageNameAndTitle = sel.Name; props.dataObject.current.Package_ID = sel.ID;},
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ]
        },
        {
            Label: translate('Distribution Requirements'),
            Field: "DistributionRequirements",
            InputType: 'ODataLookup',
            DataObject: local_dsDistReqTypes,
            Bind: sel => selectCheckBoxValues(sel,'DistributionRequirements', 'local_dsDistReqTypes'),
            Columns: [
                {field:'Name', headerName:'Name', width:300}
            ],
            MultiSelect: true,
            BindClear: () => selectCheckBoxValues(null,'DistributionRequirements', 'local_dsDistReqTypes')
        },
        {
            Label: translate('Company'),
            Field: "Company",
            InputType: 'ODataLookup',
            DataObject: local_dsCompanies,
            Bind: sel => {props.dataObject.current.Company = sel.Name; props.dataObject.current.Company_ID = sel.ID;},
            Columns: [
                {field:'Name', headerName:'Name', width:200},
                {field:'Description', headerName:'Description', width:200}
            ]
        },
        {
            Label: translate('Planned Change'),
            Field: "PlannedChange",
            InputType: 'ODataLookup',
            DataObject: local_dsPlannedChangeLkp,
            Bind: sel => {props.dataObject.current.PlannedChange = sel.Name;},
            Columns: [
                {field:'Name', headerName:'Name', width:200},
                {field:'Description', headerName:'Description', width:200}
            ]
        },
        {
            Label: translate('URL'),
            Field: "URL",
            InputType: "input"
        },
        {
            Label: translate('Alternative Title'),
            Field: "AlternativeTitle",
            InputType: "input"
        },
        {
            Label: translate('Free Text 1'),
            Field: "FreeText1",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            Bind: sel => {props.dataObject.current.FreeText1 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=1'
        },
        {
            Label: translate('Free Text 2'),
            Field: "FreeText2",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            Bind: sel => {props.dataObject.current.FreeText2 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=2'
        },
        {
            Label: translate('Free Text 3'),
            Field: "FreeText3",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            Bind: sel => {props.dataObject.current.FreeText3 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=3'
        },
        {
            Label: translate('Free Text 4'),
            Field: "FreeText4",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            Bind: sel => {props.dataObject.current.FreeText4 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=4'
        },
        {
            Label: translate('Free Text 5'),
            Field: "FreeText5",
            InputType: 'ODataLookup',
            DataObject: local_dsFreeTextLkp,
            Bind: sel => {props.dataObject.current.FreeText5 = sel.FreeText;},
            Columns: [
                {field:'FreeText', headerName:'FreeText', width:400}
            ],
            WhereClause: 'FreeTextNo=5'
        },
    ])

    local_dsCodeTypes.load().then(() => {
        local_dsCodeTypes.data.forEach((row,index) => {
            attributes.value.push(
                {
                    Label: computed(() => row.Name),
                    Field: "Code"+(index+1),
                    RequiredField: "Code"+(index+1),
                    InputType: 'ODataLookup',
                    DataObject: local_dsCodesLookup,
                    Bind: sel => {props.dataObject.current['Code'+(index+1)+"_ID"] = sel.ID, props.dataObject.current['Code'+(index+1)] = sel.Name;},
                    Columns: [
                        {field:'ID', headerName:'ID', width:100},
                        {field:'Name', headerName:'Name', width:200},
                        {field:'Title', headerName:'Title', width:200}
                    ],
                    WhereClause: "Seq = 'Code"+(index+1)+"' AND '" + props.dataObject.current.OrgUnitIdPath + "' LIKE [IdPath]+'%'"
                }
            )
        })

        local_dsMetaFields.load().then(() => {
            for(let i = 0; i < 15; i++){
                attributes.value.push(
                {
                    Label: computed(() => local_dsMetaFields.current['Meta'+(i+1)]),
                    Field: 'Meta'+(i+1),
                    RequiredField: 'Meta'+(i+1),
                    InputType: 'ODataLookup',
                    DataObject: local_dsMetaLookup,
                    Bind: sel => {props.dataObject.current['Meta'+(i+1)] = sel.ID ,props.dataObject.current['Meta'+(i+1)]= sel.Name;},
                    Columns: [
                        {field:'ID', headerName:'ID', width:100},
                        {field:'Name', headerName:'Name', width:200},
                        {field:'Description', headerName:'Description', width:200},
                    ],
                    WhereClause: "MetaType = 'Meta"+(i+1)+"' AND IdPath LIKE '" + props.dataObject.current.OrgUnitIdPath + "'"
                })
            }
            local_dsErrors.load().then(() => {
                sortAttributes();
            });
        })
    })

    function sortAttributes(){
        attributes.value.sort((a,b) => {
            if(requiredFields.value[a.RequiredField] && !requiredFields.value[b.RequiredField]){
                a.Required = true;
                return -1;
            }
            if(!requiredFields.value[a.RequiredField] && requiredFields.value[b.RequiredField]){
                b.Required = true;
                return 1;
            }
            if(requiredFields.value[a.RequiredField] &&  requiredFields.value[b.RequiredField]){
                a.Required = true;
                b.Required = true;
            }
            return 0;
        })

        attributes.value.sort((a,b) => {
            if(!requiredFields.value[a.RequiredField] && !props.dataObject.current[a.Field] && props.dataObject.current[b.Field]){
                b.IsSet = true;
                return 1;
            }
            if(!requiredFields.value[b.RequiredField] && !props.dataObject.current[b.Field] && props.dataObject.current[a.Field]){
                a.IsSet = true;
                return -1;
            }
            if(props.dataObject.current[a.Field] && props.dataObject.current[b.Field]){
                a.IsSet = true;
                b.IsSet = true;
            }
            return 0;
        })
    }

    function saveDataObject(){
        if(props.lazySave == false)
            props.dataObject.save();
    }
</script>

<template>
    <div class="row">
        <!--Using two different divs in which we render attributes depending if attribute is required,set or neither.
            Instead of running two different for cycles alternative could be by using <teleport> but at the time it didnt work for me-->
            
        <!--Before accordion show all attributes that are either required or have a value. sortAttributes() makes sure required fields are first and after them its attributes with values set-->
        <template v-for="attribute in attributes" :key="attribute.Field">
            <div v-if="attribute.Required || attribute.IsSet" class="form-group col-sm-4 col-6 p-2" :class="props.customClass"> 
                <label>{{attribute.Label?.value ?? attribute.Label}}</label>
                <ODataLookup v-if="attribute.InputType == 'ODataLookup'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" v-model="props.dataObject.current[attribute.Field]" class="form-control form-control-sm" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"/>
                <input v-if="attribute.InputType == 'input'" type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control form-control-sm" :disabled="attribute.Disabled" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}">
                <OTextArea v-if="attribute.InputType == 'OTextArea'" @blur="saveDataObject" :renderHTML="attribute.RenderHTML" rows="attribute.Rows" class="form-control form-control-sm" v-model="props.dataObject.current[attribute.Field]" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"> </OTextArea>
                <o-date-picker v-if="attribute.InputType == 'o-date-picker'" :callback="saveDataObject" v-model="props.dataObject.current[attribute.Field]" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}"></o-date-picker>
                <PersonLookup v-if="attribute.InputType == 'PersonLookup'" v-model="props.dataObject.current[attribute.Field]" :bind="sel => {attribute.Bind(sel); saveDataObject()}"  class="form-control form-control-sm" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}"></PersonLookup>

                <div class="d-flex" v-if="attribute.InputType == 'input2x'">
                    <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control form-control-sm w-25" :disabled="attribute.Disabled">
                    <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field2]" class="form-control form-control-sm w-75 ms-1" :disabled="attribute.Disabled">
                </div>
            </div>
        </template>
    </div>

    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed border" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" style="z-index: auto !important;">
                    {{$t('Attrffibutes')}}
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <input type="text" class="form-control" placeholder="Search For Attributes..." v-model="searchForAttributes">
                    <template v-for="attribute in attributes" :key="attribute.Field">
                        <div v-if="!attribute.Required && !attribute.IsSet && (attribute.Label.toLowerCase().includes(searchForAttributes.toLowerCase()) || !searchForAttributes)" class="form-group col-sm-4 col-6 p-2" :class="props.customClass"> 
                            <label>{{attribute.Label?.value ?? attribute.Label}}</label>
                            <ODataLookup v-if="attribute.InputType == 'ODataLookup'" :bind="sel => {attribute.Bind(sel); saveDataObject()}" v-model="props.dataObject.current[attribute.Field]" class="form-control form-control-sm" :data-object="attribute.DataObject" :columns="attribute.Columns" :whereClause="attribute.WhereClause" :multiselect="attribute.MultiSelect" :bindClear="attribute.BindClear" :class="{'border border-danger':attribute.Required  && !props.dataObject.current[attribute.Field]}"/>
                            <input v-if="attribute.InputType == 'input'" type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control form-control-sm" :disabled="attribute.Disabled" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}">
                            <OTextArea v-if="attribute.InputType == 'OTextArea'" @blur="saveDataObject" :renderHTML="attribute.RenderHTML" rows="attribute.Rows" class="form-control form-control-sm" v-model="props.dataObject.current[attribute.Field]" :class="{'border border-danger':attribute.Required && !props.dataObject.current[attribute.Field]}"> </OTextArea>
                            <o-date-picker v-if="attribute.InputType == 'o-date-picker'" :callback="saveDataObject" v-model="props.dataObject.current[attribute.Field]" format="Short Date" :inputClassName="{'form-control form-control-sm':true,'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}"></o-date-picker>
                            <PersonLookup v-if="attribute.InputType == 'PersonLookup'" v-model="props.dataObject.current[attribute.Field]" :bind="sel => {attribute.Bind(sel); saveDataObject()}" class="form-control form-control-sm" :class="{'border border-danger': attribute.Required && !props.dataObject.current[attribute.Field]}"></PersonLookup>

                            <div class="d-flex" v-if="attribute.InputType == 'input2x'">
                                <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field]" class="form-control form-control-sm w-25" :disabled="attribute.Disabled">
                                <input type="text" @blur="saveDataObject" v-model="props.dataObject.current[attribute.Field2]" class="form-control form-control-sm w-75 ms-1" :disabled="attribute.Disabled">
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>